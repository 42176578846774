<template>
  <v-container>
    <h1>{{ verb }} Student</h1>
    <v-sheet color="white" elevation="1" class="px-5 mt-4 rounded">
      <v-form
        ref="form"
        v-model="valid"
        lazy-validation
        @submit.prevent="submit"
      >
        <v-row>
          <v-col>
            <v-autocomplete
              v-model="form.institute_id"
              :disabled="loading"
              :items="institutes"
              :loading="instituteLoading"
              :rules="rules.institute"
              :search-input.sync="searchInstitute"
              label="Institute"
              item-text="name"
              item-value="id"
              cache-items
              flat
              hide-no-data
            ></v-autocomplete>
            <v-autocomplete
              v-model="form.user_id"
              :disabled="loading"
              :items="users"
              :loading="userLoading"
              :rules="rules.user"
              :search-input.sync="searchUser"
              label="User"
              item-text="name"
              item-value="id"
              cache-items
              flat
              hide-no-data
            ></v-autocomplete>
            <v-menu
              v-model="dateOfBirthMenu"
              :close-on-content-click="false"
              :nudge-right="40"
              transition="scale-transition"
              offset-y
              min-width="auto"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  v-model="form.date_of_birth"
                  v-bind="attrs"
                  v-on="on"
                  :rules="rules.date_of_birth"
                  :disabled="loading"
                  label="Date of Birth"
                  readonly
                ></v-text-field>
              </template>
              <v-date-picker
                v-model="form.date_of_birth"
                @input="dateOfBirthMenu = false"
                :max="maximumDate"
                :disabled="loading"
                no-title
                scrollable
              ></v-date-picker>
            </v-menu>
            <v-text-field
              v-model="form.school"
              :disabled="loading"
              :rules="rules.school"
              label="School"
            />
            <v-select
              v-model="form.class"
              :disabled="loading"
              :items="classItems"
              :rules="rules.class"
              label="Class"
            />
            <v-select
              v-model="form.group"
              :disabled="loading"
              :items="groupItems"
              :rules="rules.group"
              label="Group"
            />
            <v-text-field
              v-model="form.major_option1"
              :disabled="loading"
              :rules="rules.major_option1"
              hint="Major - College"
              label="Major Option 1"
              persistent-hint
            />
            <v-text-field
              v-model="form.major_option2"
              :disabled="loading"
              :rules="rules.major_option2"
              hint="Major - College"
              label="Major Option 2"
              persistent-hint
            />
            <v-text-field
              v-model="form.parents_name"
              :disabled="loading"
              :rules="rules.parents_name"
              label="Parents' Name"
            />
            <v-text-field
              v-model="form.parents_phone_number"
              :disabled="loading"
              :rules="rules.parents_phone_number"
              label="Parents' Phone Number"
              prefix="+62"
            />
            <v-text-field
              v-model="form.parents_email"
              :disabled="loading"
              :rules="rules.parents_email"
              label="Parents' E-mail"
            />
            <v-btn
              :disabled="!valid"
              :loading="loading"
              color="success"
              class="mt-2"
              type="submit"
            >
              Submit
            </v-btn>
          </v-col>
        </v-row>
      </v-form>
    </v-sheet>
  </v-container>
</template>

<script>
import { mapActions } from "vuex";
import Institute from "@/services/institute";
import Student from "@/services/student";
import User from "@/services/user";

export default {
  name: "Student.Form",
  data() {
    return {
      valid: false,
      form: {
        user_id: "",
        institute_id: "",
        date_of_birth: "",
        school: "",
        class: "",
        group: "",
        major_option1: "",
        major_option2: "",
        parents_name: "",
        parents_phone_number: "",
        parents_email: ""
      },
      dateOfBirthMenu: false,
      institutes: [],
      users: [],
      rules: {
        institute: [v => !!v || "Institute is required"],
        user: [v => !!v || "Institute is required"],
        date_of_birth: [v => !!v || "Date of birth is required"],
        school: [
          v => !!v || "School is required",
          v =>
            (v.length >= 5 && v.length <= 50) ||
            "School must be greater than 5 and less then 50",
          v =>
            new RegExp(/^\s*\w+(?:[^\w,]+\w+)*[^,\w]*$/i).test(v) ||
            "School must only contain alphanumeric characters and dashes"
        ],
        class: [v => !!v || "Class is required"],
        group: [v => !!v || "Group is required"],
        major_option1: [
          v => !!v || "Major option 1 is required",
          v =>
            (v.length >= 5 && v.length <= 100) ||
            "Major option 1 must be greater than 5 and less then 100"
        ],
        major_option2: [
          v => !!v || "Major option 2 is required",
          v =>
            (v.length >= 5 && v.length <= 100) ||
            "Major option 2 must be greater than 5 and less then 100"
        ],
        parents_name: [
          v => !!v || "Parents' name is required",
          v =>
            (v.length >= 5 && v.length <= 50) ||
            "Parents' name must be greater than 5 and less then 50",
          v =>
            new RegExp(/^\s*\w+(?:[^\w,]+\w+)*[^,\w]*$/i).test(v) ||
            "Parents' name must only contain alphanumeric characters"
        ],
        parents_phone_number: [
          v => !!v || "Parents' phone number is required",
          v =>
            /^8\d{8,11}$/.test(v) ||
            "Parents' phone number does not match with the format"
        ],
        parents_email: [
          v => !!v || "Parents' e-mail is required",
          v =>
            /^(([^<>()[\]\\.,;:\s@']+(\.[^<>()\\[\]\\.,;:\s@']+)*)|('.+'))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
              v
            ) || "Parents' e-mail must be valid"
        ]
      },
      classItems: [
        "10 IPA 1",
        "10 IPA 2",
        "10 IPA 3",
        "10 IPA 4",
        "10 IPA 5",
        "10 IPA 6",
        "10 IPA 7",
        "10 IPA 8",
        "10 IPS 1",
        "10 IPS 2",
        "10 IPS 3",
        "10 IPS 4",
        "10 IPS 5",
        "10 IPS 6",
        "10 IPS 7",
        "10 IPS 8",
        "11 IPA 1",
        "11 IPA 2",
        "11 IPA 3",
        "11 IPA 4",
        "11 IPA 5",
        "11 IPA 6",
        "11 IPA 7",
        "11 IPA 8",
        "11 IPS 1",
        "11 IPS 2",
        "11 IPS 3",
        "11 IPS 4",
        "11 IPS 5",
        "11 IPS 6",
        "11 IPS 7",
        "11 IPS 8",
        "12 IPA 1",
        "12 IPA 2",
        "12 IPA 3",
        "12 IPA 4",
        "12 IPA 5",
        "12 IPA 6",
        "12 IPA 7",
        "12 IPA 8",
        "12 IPS 1",
        "12 IPS 2",
        "12 IPS 3",
        "12 IPS 4",
        "12 IPS 5",
        "12 IPS 6",
        "12 IPS 7",
        "12 IPS 8"
      ],
      groupItems: ["SAINTEK", "SOSHUM", "CAMPURAN"],
      searchInstitute: null,
      searchUser: null,
      instituteLoading: false,
      userLoading: false,
      loading: false
    };
  },
  computed: {
    verb() {
      return this.$route.params.id ? "Update" : "Create";
    },
    pastVerb() {
      return `${this.verb}d`;
    },
    dataId() {
      return this.$route.params.id;
    },
    maximumDate() {
      const tzoffset = new Date().getTimezoneOffset() * 60000; //offset in milliseconds
      return new Date(Date.now() - tzoffset).toISOString().slice(0, -1);
    }
  },
  watch: {
    searchInstitute(val) {
      val && val !== this.form.institute_id && this.searchInstitutes(val);
    },
    searchUser(val) {
      val && val !== this.form.user_id && this.searchUsers(val);
    }
  },
  async created() {
    this.setNavbarTitle("Manage Student");
    if (this.dataId) {
      const student = await Student.find(this.dataId, true);
      const {
        userId: user_id,
        instituteId: institute_id,
        dateOfBirth: date_of_birth,
        majorOption1: major_option1,
        majorOption2: major_option2,
        parentsName: parents_name,
        parentsPhoneNumber: parents_phone_number,
        parentsEmail: parents_email,
        examGroup: group,
        ...studentData
      } = student.data.data;
      console.log(student, studentData);
      this.form = {
        ...studentData,
        user_id,
        institute_id,
        group,
        date_of_birth:
          date_of_birth !== null ? date_of_birth.slice(0, 10) : null,
        major_option1,
        major_option2,
        parents_name,
        parents_phone_number:
          parents_phone_number !== null ? parents_phone_number.slice(3) : null,
        parents_email
      };
      const institute = await Institute.find(institute_id);
      this.institutes.push({
        id: institute_id,
        name: institute.data.data.name
      });
      const user = await User.find(user_id);
      this.users.push({ id: user_id, name: user.data.data.name });
      this.$refs.form.resetValidation();
    }
  },
  methods: {
    ...mapActions("drawer", ["setNavbarTitle"]),
    async searchInstitutes(query) {
      this.instituteLoading = true;
      const institutes = await Institute.search(query);
      this.institutes = institutes.data.data.institutes;
      this.instituteLoading = false;
    },
    async searchUsers(query) {
      this.userLoading = true;
      const searchType = "student";
      const users = await User.search({ query, type: searchType });
      this.users = users.data.data.users;
      this.userLoading = false;
    },
    async submit() {
      if (this.$refs.form.validate()) {
        this.loading = true;
        try {
          if (this.dataId) {
            await Student.update(this.dataId, {
              ...this.form,
              parents_phone_number: `+62${this.form.parents_phone_number}`
            });
          } else {
            await Student.create({
              ...this.form,
              parents_phone_number: `+62${this.form.parents_phone_number}`
            });
          }

          this.$snackbar.showMessage({
            content: `Data ${this.pastVerb.toLowerCase()}`,
            variant: "success"
          });
          this.$router.push({ name: "Student.Index" });
        } catch (error) {
          this.loading = false;
        }
      }
    }
  }
};
</script>
